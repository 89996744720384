<template>
  <b-card>
    <FormBuilder
      label="CMS"
      create
      :fields="fields"
      :loading-submit="$store.state.cms.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'slug',
          label: 'Slug',
          required: true,
        },
        {
          key: 'type',
          label: 'Type',
          type: 'select',
          options: [
            {
              text: 'Page',
              value: 'page',
            },
            {
              text: 'Article',
              value: 'article',
            },
            {
              text: 'Press',
              value: 'press',
            },
          ],
          required: true,
        },
        {
          key: 'featuredImage',
          label: 'Featured Image',
          type: 'image',
          required: true,
        },
        {
          key: 'summary',
          label: 'Summary',
          required: true,
        },
        {
          key: 'content',
          label: 'Content',
          type: 'tinymce',
          required: true,
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('cms/create', data)
        .then(callback)
    },
  },
}
</script>

    <style scoped>

    </style>
